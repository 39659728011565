import React from 'react';

function QRCodeForm({ inputValue, onInputChange, placeholder }) {
  return (
    <div className="QRCodeForm">
      <input
        type="text"
        value={inputValue}
        onChange={onInputChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default QRCodeForm;

import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeDisplay({ qrValue, color }) {
  const qrCodeRef = React.useRef(null);

  const downloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = url;
      link.download = 'qrcode.png';
      link.click();
    }
  };

  return (
    <div className="QRCodeDisplay">
      {qrValue && (
        <div ref={qrCodeRef} style={{ width: '300px', height: '300px' }}>
          <QRCode
            value={qrValue}
            size={1000} // Set size for downloadable image
            style={{ width: '100%', height: '100%' }}
            fgColor={color} // Use the selected color
          />
        </div>
      )}
      {qrValue && (
        <button onClick={downloadQRCode}>Download QR Code</button>
      )}
    </div>
  );
}

export default QRCodeDisplay;

import React, { useState } from 'react';
import QRCodeDisplay from './components/QRCodeDisplay'; // Import QRCodeDisplay
import QRCodeForm from './components/QRCodeForm'; // Import QRCodeForm
import './App.css';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [qrType, setQrType] = useState('url'); // Default QR code type
  const [qrColor, setQrColor] = useState('#000000'); // Default QR code color

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleTypeChange = (type) => {
    setQrType(type);
  };

  const handleColorChange = (e) => {
    setQrColor(e.target.value);
  };

  // Prepare QR code value based on type
  const generateQRCodeValue = () => {
    const defaultURL = 'https://example.com';

    switch (qrType) {
      case 'phone':
        return inputValue ? `tel:${inputValue}` : '';
      case 'email':
        return inputValue ? `mailto:${inputValue}` : '';
      case 'text':
        return inputValue;
      case 'url':
      default:
        return inputValue || defaultURL;
    }
  };

  // Determine placeholder based on type
  const getPlaceholder = () => {
    switch (qrType) {
      case 'phone':
        return 'Enter phone number';
      case 'email':
        return 'Enter email address';
      case 'text':
        return 'Enter plain text';
      case 'url':
      default:
        return 'Enter URL';
    }
  };

  return (
    <div className="App">
      <h1>QR Code Generator</h1>
      <div className="navbar">
        <button onClick={() => handleTypeChange('url')}>URL</button>
        <button onClick={() => handleTypeChange('phone')}>Phone Number</button>
        <button onClick={() => handleTypeChange('email')}>Email</button>
        <button onClick={() => handleTypeChange('text')}>Plain Text</button>
      </div>
      <QRCodeForm
        inputValue={inputValue}
        onInputChange={handleInputChange}
        placeholder={getPlaceholder()}
      />
      <div className="color-picker">
        <label htmlFor="qrColor">QR Code Color:</label>
        <input
          type="color"
          id="qrColor"
          value={qrColor}
          onChange={handleColorChange}
        />
      </div>
      <QRCodeDisplay qrValue={generateQRCodeValue()} color={qrColor} />
    </div>
  );
}

export default App;
